import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: {
      path: '/company/index'
    }
  },

  {
    path: '/company/index',
    name: 'index',
    meta: { title: "首页" },
    component: () => import('@/views/company/index.vue')
  }, {
    path: '/form/index',
    name: 'form',
    meta: { title: "编辑信息" },
    component: () => import('@/views/Form/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '吉祥格通信';
  next();
});
export default router
