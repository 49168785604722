import Vue from 'vue'
import router from '@/router'
import axios from 'axios'
import { Message } from 'element-ui'
// 正式
axios.defaults.baseURL = 'https://jxg.jxg114.com'
// axios.defaults.baseURL = '/cmp'

// axios.defaults.timeout = 10000
Vue.prototype.$http = axios

// 请求拦截器配置配置header
axios.interceptors.request.use(function (config) {
  // showFullScreenLoading()
  // 在发送请求之前做些什么
  var mytoken = window.localStorage.getItem('token')
  if (mytoken) {
    config.headers.token = mytoken
    config.headers.AccessControlAllowOrigin = '*'
  }
  return config
}, function (error) {
  Message.error({ message: '请求超时', duration: 3000 })
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 响应拦截器配置401
axios.interceptors.response.use(function (response) {
  if (response.data.code == 1) {
    return response.data
  }

  else if (response.data.code == 0) {
    if (response.data.msg == '二维码已经过期') {
      router.push('/')
      localStorage.removeItem('token')
    }
    // router.push('/Index/index')
    // Message.error({ message: response.data.msg, duration: 3000 })
    // return Promise.reject(response.data)
    // return response.data
    Message.error({ message: response.data.msg, duration: 3000 })
  }
  // else {
  //   Message.error({ message: response.data.msg, duration: 3000 })
  // }
  return response.data
}, function (error) {
  console.log(error.response, 'error');
  if (error.response.status == 403) {
    Message.error({ message: '权限不足,请联系管理员!', duration: 3000 })
  }
  return error.response
  // if (error.response.status == 401) {
  //   var mytoken = window.localStorage.getItem('token')
  //   if (mytoken) {
  //     Message({ message: '登录过期，请重新登录', duration: 3000 })
  //     window.localStorage.removeItem('token')
  //     //   bus.$emit('updateToken', window.localStorage.getItem('TOKEN'))
  //   } else {
  //     Message.error({ message: '请先登录', duration: 3000 })
  //   }
  //   router.push('/login/index')
  // } else
  //   if (error.response.status == 400) {
  //     Message.error({ message: error.response.data.message, duration: 3000 })
  //   } else if (error.response.status == 504 || error.response.status == 404) {
  //     Message.error({ message: '服务器被吃了⊙﹏⊙∥', duration: 3000 })
  //   } else if (error.response.status == 403) {
  //     Message.error({ message: '权限不足,请联系管理员!', duration: 3000 })
  //   } else {
  //     Message.error({ message: error.response.data.message, duration: 3000 })
  //   }
  // return Promise.reject(error)
})
// axios.defaults.timeout = 10000
